/*
 * Copyright 2020 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2020.06.19 at 19:01
 */

import {forEach} from '@labor-digital/helferlein';
import {PlainObject} from '@labor-digital/helferlein/lib/Interfaces/PlainObject';
import {getPath} from '@labor-digital/helferlein/lib/Lists/Paths/getPath';
import {isEmpty} from '@labor-digital/helferlein/lib/Types/isEmpty';
import {isUndefined} from '@labor-digital/helferlein/lib/Types/isUndefined';
import {MainMenuFirstLevelLink} from '../../../../Interface/Links';

export interface MainMenuModuleDefinition
{
    component: any;
    props: PlainObject;
    key: string;
}

export default {
    data()
    {
        return {
            /**
             * Defines the link object for which "module" should build the module list
             */
            moduleTargetLink: null as null | MainMenuFirstLevelLink,

            /**
             * Set this to true in your parent component to render the modules for the mobile menu
             */
            modulesForMobileMenu: false,

            /**
             * Set this to true if you want to render the special "branch" module list
             */
            modulesForBranchMenu: false,

            /**
             * Allows you to inject additional props into your modules
             * You should use the module key as property name
             */
            additionalModuleProps: {}
        };
    },
    methods: {
        getSubPagesModule()
        {
            return {
                component: () => import('./MobileSubMenuModule/MobileSubMenuModule.vue'),
                props: {
                    ...this.additionalModuleProps.submenu ?? {}
                },
                key: 'submenu'
            };
        },

        /**
         * Parse the nav title if available
         *
         * @param title
         */
        getNavTitle(title)
        {
            return isEmpty(title.navTitle) ? title.title : title.navTitle;
        },

        rewriteTitle(children)
        {
            forEach(children, child => {
                child.title = this.getNavTitle(child);
            });

            return children;
        }

    },
    computed: {
        /**
         * Internal only, a list of variables we need while building the module list
         * @private
         */
        _moduleArgs(): PlainObject
        {
            const target = this.moduleTargetLink;
            return {
                title: this.getNavTitle(target),
                teaserText: target.teaser,
                teaserLink: getPath(target, 'children.0.href', ''),
                link: target.href,
                layout: target.layout,
                children: target.children,
                hasTopics: !isEmpty(getPath(target, 'topics.children')),
                topics: target.topics,
                hasRelevant: !isEmpty(getPath(target, 'relevant.children')),
                relevant: target.relevant
            };
        },

        /**
         * Builds the list of modules for the currently selected page
         */
        modules(): Array<MainMenuModuleDefinition>
        {
            let modules: Array<MainMenuModuleDefinition> = [];

            // Ignore if there are is no link set
            if (this.moduleTargetLink === null ||
                isUndefined(this.moduleTargetLink.layout) && !this.modulesForBranchMenu) {
                modules.push(this.getSubPagesModule());
                return modules;
            }

            // Prepare module args
            const args = this._moduleArgs;

            // Build the special branch module list
            if (this.modulesForBranchMenu) {
                modules.push({
                    component: () => import('./BranchSearchModule/BranchSearchModule.vue'),
                    props: {
                        ...this.additionalModuleProps.branchSearch ?? {},
                        title: this.getNavTitle(args),
                        teaserText: args.teaserText,
                        applicationLink: args.teaserLink,
                        link: args.link,
                        layout: args.layout,
                        context: this.context
                    },
                    key: 'branchSearch'
                });
                return modules;
            }

            // Teaser Module
            modules.push({
                component: () => import('./TeaserModule/TeaserModule.vue'),
                props: {
                    ...this.additionalModuleProps.teaser ?? {},
                    title: this.getNavTitle(args),
                    teaserText: args.teaserText,
                    applicationLink: args.teaserLink,
                    link: args.link,
                    layout: args.layout
                },
                key: 'teaser'
            });

            // Listing for the mobile menu
            if (this.modulesForMobileMenu) {
                // Sub pages Module
                modules.push(this.getSubPagesModule());

                // Form Modules
                modules = modules.concat(this.formModules);
            }


            // Topics Module
            if (args.hasTopics) {
                modules.push({
                    component: () => import('./TopicsModule/TopicsModule.vue'),
                    props: {
                        ...this.additionalModuleProps.topics ?? {},
                        links: this.rewriteTitle(args.topics.children),
                        title: this.getNavTitle(args.topics)
                    },
                    key: 'topics'
                });
            }

            // Relevant Topics Module
            if (args.hasRelevant) {
                modules.push({
                    component: () => import('./RelevantTopicsModule/RelevantTopicsModule.vue'),
                    props: {
                        ...this.additionalModuleProps.relevant ?? {},
                        links: this.rewriteTitle(args.relevant.children),
                        title: this.getNavTitle(args.relevant)
                    },
                    key: 'relevant'
                });
            }

            // Done
            return modules;
        },

        /**
         * Builds the list of form modules on the currently selected page
         */
        formModules(): Array<MainMenuModuleDefinition>
        {
            // Ignore if there are is no link set
            if (this.moduleTargetLink === null) {
                return [];
            }

            // Prepare module args
            const modules: Array<MainMenuModuleDefinition> = [];
            const args = this._moduleArgs;

            // Job form module
            if (args.layout === 'findJob') {
                let component = this.modulesForMobileMenu ? () => import('./MobileFindJobModule/MobileFindJobModule.vue')
                        : () => import('./FindJobModule/FindJobModule.vue');
                modules.push({
                    component: component,
                    props: {
                        context: this.context
                    },
                    key: 'findJob'
                });
            }

            // Request employees
            if (args.layout === 'requestEmployees') {
                modules.push({
                    component: () => import('./RequestEmployeesModule/RequestEmployeesModule.vue'),
                    props: {
                        context: this.context
                    },
                    key: 'requestEmployees'
                });
            }

            // Done
            return modules;
        }
    }
};
