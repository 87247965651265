













































































import {PlainObject} from '@labor-digital/helferlein/lib/Interfaces/PlainObject';
import {isEmpty} from '@labor-digital/helferlein/lib/Types/isEmpty';
import {isPlainObject} from '@labor-digital/helferlein/lib/Types/isPlainObject';
import {PageContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/PageContext';
import {AppEventKeys} from '../../../../AppEventKeys';
import {AppStoreKeys} from '../../../../AppStoreKeys';
import {MasterToggleLink} from '../../../../Interface/Links';
import ComponentProxyAwareMixin from '../../../../Mixin/ComponentProxyAwareMixin';
import LanguageSwitchMixin from '../../../../Mixin/LanguageSwitchMixin';
import {MenuService} from '../../../../Service/MenuService';
import Icon from '../../../Misc/Icon/Icon.vue';
import HomeLinkWrap from '../../../Misc/Link/HomeLinkWrap/HomeLinkWrap.vue';
import LinkWrap from '../../../Misc/Link/LinkWrap/LinkWrap.vue';
import MenuModuleRenderingMixin from '../Module/MenuModuleRenderingMixin';

export default {
    name: 'MainMenuMobile',
    components: {HomeLinkWrap, LinkWrap, Icon},
    mixins: [MenuModuleRenderingMixin, ComponentProxyAwareMixin, LanguageSwitchMixin],
    props: {
        context: null as PageContext
    },
    data()
    {
        return {
            page: {
                current: null,
                history: null
            }
        };
    },
    computed: {
        isLandingpage(): Boolean
        {
            return MenuService.isHomepageForLandingpages() || MenuService.isLandingpage;
        },
        items(): Array<PlainObject>
        {
            return MenuService.getMainMenuFirstLevel(true);
        },
        itemsMasterToggle(): Array<MasterToggleLink>
        {
            return MenuService.getMasterToggleLinks();
        },
        itemsMetaMenu(): Array<PlainObject>
        {
            return MenuService.getMetaMenu();
        },
        itemsSubPages(): Array<PlainObject>
        {
            if (this.page.current.children) {
                return this.page.current.children;
            }

            return [];
        }
    },
    methods: {
        pageNext(page: PlainObject)
        {
            if (!page.children) {
                return;
            }
            this.page.history.push(page);
            this.moduleTargetLink = page;
            this.page.current = page;
        },
        pagePrev()
        {
            this.page.history.pop();
            if (this.page.history.length === 0) {
                this.page.current = null;
            } else {
                this.page.current = this.page.history[this.page.history.length - 1];
            }
            this.moduleTargetLink = this.page.current;
        },
        activeItemClass(page: PlainObject, isMasterToggle?: boolean)
        {
            let isActive = isPlainObject(page) && MenuService.isIdInRootLine(page.id);
            if (isMasterToggle) {
                isActive = (page as MasterToggleLink).href === MenuService.getHomeLink();
            }
            return {
                'mainMenuMobile__listItem--active': isActive
            };
        },
        hasChildren(page: PlainObject): boolean
        {
            return isPlainObject(page) && !isEmpty(page.children);
        },
        onLoginClick()
        {
            this.context.eventEmitter.emit(AppEventKeys.OPEN_LOGIN_MENU);
        }
    },
    created()
    {
        this.modulesForMobileMenu = true;
        this.additionalModuleProps.submenu = {
            mainMenuComponent: this
        };

        // Add watcher to reset the open page when the sidebar closes
        this.$watch(() => this.context.store.get(AppStoreKeys.SIDEBAR_DIRECTION), (n) => {
            if (n !== null) {
                this.page = {
                    current: this.isMainLanguage ? null : MenuService.mainMenu[0],
                    history: []
                };
                return;
            }
        });
    }
};
