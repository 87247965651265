var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLandingpage
    ? _c("div", { staticClass: "mainMenuMobile" }, [
        !_vm.page.current
          ? _c(
              "ul",
              {
                staticClass:
                  "mainMenuMobile__list mainMenuMobile__list--mainToggle",
              },
              _vm._l(_vm.itemsMasterToggle, function (item) {
                return _c(
                  "li",
                  {
                    staticClass: "mainMenuMobile__listItem",
                    class: _vm.activeItemClass(item, true),
                  },
                  [
                    _c(
                      "link-wrap",
                      {
                        attrs: { link: item.href, type: "js" },
                        on: {
                          click: function ($event) {
                            return _vm.pageNext(item)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t(item.title)) +
                            "\n                "
                        ),
                        _c("icon", {
                          staticClass: "children",
                          attrs: { icon: "arrowLeft" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.page.current
          ? _c(
              "ul",
              {
                staticClass:
                  "mainMenuMobile__list mainMenuMobile__list--metaMenu",
              },
              [
                _vm._l(_vm.itemsMetaMenu, function (item) {
                  return _c(
                    "li",
                    {
                      staticClass: "mainMenuMobile__listItem",
                      class: _vm.activeItemClass(item),
                    },
                    [
                      _c(
                        "link-wrap",
                        {
                          attrs: {
                            link: item.href,
                            type: item.children ? "js" : "auto",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.pageNext(item)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(item.title) +
                              "\n                "
                          ),
                          item.children
                            ? _c("icon", {
                                staticClass: "children",
                                attrs: { icon: "arrowLeft" },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "mainMenuMobile__listItem" },
                  [
                    _c(
                      "link-wrap",
                      {
                        attrs: { link: "#nogo-login", type: "js" },
                        on: { click: _vm.onLoginClick },
                      },
                      [
                        _c("icon", { attrs: { icon: "maleCircle" } }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("navigation.login.text"))),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.page.current
          ? _c(
              "ul",
              {
                staticClass:
                  "mainMenuMobile__list mainMenuMobile__list--subPages",
              },
              [
                _vm.isMainLanguage
                  ? _c(
                      "li",
                      { staticClass: "mainMenuMobile__listItem" },
                      [
                        _c(
                          "link-wrap",
                          {
                            attrs: { link: _vm.page.current.href, type: "js" },
                            on: { click: _vm.pagePrev },
                          },
                          [
                            _c("icon", {
                              staticClass: "back",
                              attrs: { icon: "arrowLeft" },
                            }),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("navigation.mobile.back"))),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.page.current
                  ? _c(
                      "li",
                      {
                        staticClass:
                          "mainMenuMobile__listItem mainMenuMobile__listItem--current",
                      },
                      [
                        _c(
                          "link-wrap",
                          { attrs: { link: _vm.page.current.href } },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.page.current.masterToggle === true
                                    ? _vm.$t(_vm.page.current.title)
                                    : _vm.page.current.title
                                )
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "mainMenuMobile__listItem" },
                  _vm._l(_vm.modules, function (module) {
                    return _c(
                      module.component,
                      _vm._b(
                        { key: module.key, tag: "component" },
                        "component",
                        module.props,
                        false
                      )
                    )
                  }),
                  1
                ),
              ]
            )
          : _vm._e(),
      ])
    : _c("div", { staticClass: "mainMenuMobile" }, [
        _c(
          "ul",
          { staticClass: "mainMenuMobile__list" },
          _vm._l(_vm.items, function (item) {
            return _c(
              "li",
              { staticClass: "mainMenuMobile__listItem" },
              [
                _c("link-wrap", { attrs: { link: item.slug } }, [
                  _vm._v(
                    "\n                " + _vm._s(item.title) + "\n            "
                  ),
                ]),
              ],
              1
            )
          }),
          0
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }